import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import LinkContainer from "../Utils/LinkContainer";

class TestResultLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      result: null,
    };
  }

  componentDidMount() {
    if (this.state.result) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .result("fstil", this.props.authUser.uid)
      .on("value", (snapshot) => {
        this.setState({
          result: snapshot.val(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.result("fstil", this.props.authUser.uid).off();
  }

  render() {
    const { result, loading } = this.state;

    return (
      <div>
        {loading && <div>...</div>}
        {!loading && this.state.result && (
          <LinkContainer>
            <Link to={ROUTES.TEST_FUEHRUNGSSTIL}>Meine Antworten ↗</Link>
            <Link to={ROUTES.TEST_FUEHRUNGSSTIL_RESULT}>Zum Ergebnis ↗</Link>
          </LinkContainer>
        )}
        {!loading && !this.state.result && (
          <Link to={ROUTES.TEST_FUEHRUNGSSTIL}>Zum Profil ↗</Link>
        )}
      </div>
    );
  }
}

export default withFirebase(TestResultLinks);
