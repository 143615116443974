import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import Chart from "react-apexcharts";

class SelbstmanagementResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      result: null,
      options: {
        chart: {
          width: "auto",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#129BDB"],
          dashArray: 0,
        },
        dataLabels: {
          style: {
            fontSize: "12px",
            fontFamily: "Red Hat Text, sans-serif",
            fontWeight: 300,
            colors: ["#129BDB"],
          },
          enabled: true,
          background: {
            enabled: true,
            borderRadius: 0,
            foreColor: "#ffffff",
            borderColor: "var(--border-color)",
            opacity: "100%",
          },
        },
        yaxis: {
          show: false,
          tickAmount: 24,
          max: 24,
          labels: {
            formatter: function (val, index) {
              return val.toFixed(0);
            },
          },
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            // minHeight: undefined,
            // maxHeight: 120,
            style: {
              colors: [
                "white",
                "white",
                "white",
                "white",
                "white",
                "white",
                "white",
                "white",
                "white",
                "white",
              ],
              fontSize: "15px",
              fontFamily: "Red Hat Text, sans-serif",
              fontWeight: 300,
              cssClass: "body",
            },
          },
          categories: [
            "Umgang mit Stress",
            "Einstellung zur Perfektion",
            "Delegation",
            "Ziele setzen",
            "Prioritäten setzen",
            "Organisation und Struktur",
            "Inselzeiten",
            "Besprechungen und Gespräche",
            "Informationskultur",
            "Arbeitsplanung",
          ],
        },
        plotOptions: {
          radar: {
            polygons: {
              strokeColor: "#e8e8e8",
              fill: {
                colors: [
                  "#A9DC76",
                  "#A9DC76",
                  "#A9DC76",
                  "#A9DC76",
                  "#FFD866",
                  "#FFD866",
                  "#FFD866",
                  "#FFD866",
                  "#FFD866",
                  "#FC9867",
                  "#FC9867",
                  "#FC9867",
                  "#FC9867",
                  "#FC9867",
                  "#FC9867",
                  "#FE1130",
                  "#FE1130",
                  "#FE1130",
                  "#FE1130",
                  "#FE1130",
                  "#FE1130",
                  "#FE1130",
                  "#FE1130",
                  "#FE1130",
                ],
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 700,
            options: {
              chart: {
                width: "700px",
              },
            },
          },
        ],
      },
      series: [
        {
          name: "Ergebnis",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    };
  }

  valueToEvaluationString(value) {
    if (value <= 9) {
      return "Sie können Ihren Umgang mit der Zeit in dieser Kategorie wesentlich effektiver gestalten. Fangen Sie sofort an.";
    } else if (value >= 10 && value <= 15) {
      return "Sie haben viele Möglichkeiten Ihren Umgang mit der Zeit in dieser Kategorie zu optimieren. Nutzen Sie sie.";
    } else if (value >= 16 && value <= 20) {
      return "Sie befinden sich im Durchschnitt in dieser Kategorie. Wenn Sie zu denen gehören möchten, die mit Ihrer Zeit besonders gut umgehen können, konzentrieren Sie sich auf die Kategorien mit der geringsten Punktzahl.";
    } else if (value >= 21) {
      return "Ein Lob für Ihr Zeitmanagement in dieser Kategorie. Ob Sie im Detail noch etwas verbessern können? Lassen Sie sich überraschen.";
    }
  }

  calculateEvaluation() {
    var stress =
      Number(this.state.result.result["q1"]) +
      Number(this.state.result.result["q11"]) +
      Number(this.state.result.result["q21"]) +
      Number(this.state.result.result["q31"]) +
      Number(this.state.result.result["q41"]) +
      Number(this.state.result.result["q51"]);

    var perfektion =
      Number(this.state.result.result["q2"]) +
      Number(this.state.result.result["q12"]) +
      Number(this.state.result.result["q22"]) +
      Number(this.state.result.result["q32"]) +
      Number(this.state.result.result["q42"]) +
      Number(this.state.result.result["q52"]);

    var delegation =
      Number(this.state.result.result["q3"]) +
      Number(this.state.result.result["q13"]) +
      Number(this.state.result.result["q23"]) +
      Number(this.state.result.result["q33"]) +
      Number(this.state.result.result["q43"]) +
      Number(this.state.result.result["q53"]);

    var ziele =
      Number(this.state.result.result["q4"]) +
      Number(this.state.result.result["q14"]) +
      Number(this.state.result.result["q24"]) +
      Number(this.state.result.result["q34"]) +
      Number(this.state.result.result["q44"]) +
      Number(this.state.result.result["q54"]);

    var prioritaeten =
      Number(this.state.result.result["q5"]) +
      Number(this.state.result.result["q15"]) +
      Number(this.state.result.result["q25"]) +
      Number(this.state.result.result["q35"]) +
      Number(this.state.result.result["q45"]) +
      Number(this.state.result.result["q55"]);

    var organisation =
      Number(this.state.result.result["q6"]) +
      Number(this.state.result.result["q16"]) +
      Number(this.state.result.result["q26"]) +
      Number(this.state.result.result["q36"]) +
      Number(this.state.result.result["q46"]) +
      Number(this.state.result.result["q56"]);

    var inselzeiten =
      Number(this.state.result.result["q7"]) +
      Number(this.state.result.result["q17"]) +
      Number(this.state.result.result["q27"]) +
      Number(this.state.result.result["q37"]) +
      Number(this.state.result.result["q47"]) +
      Number(this.state.result.result["q57"]);

    var besprechungen =
      Number(this.state.result.result["q8"]) +
      Number(this.state.result.result["q18"]) +
      Number(this.state.result.result["q28"]) +
      Number(this.state.result.result["q38"]) +
      Number(this.state.result.result["q48"]) +
      Number(this.state.result.result["q58"]);

    var informationskultur =
      Number(this.state.result.result["q9"]) +
      Number(this.state.result.result["q19"]) +
      Number(this.state.result.result["q29"]) +
      Number(this.state.result.result["q39"]) +
      Number(this.state.result.result["q49"]) +
      Number(this.state.result.result["q59"]);

    var arbeitsplanung =
      Number(this.state.result.result["q10"]) +
      Number(this.state.result.result["q20"]) +
      Number(this.state.result.result["q30"]) +
      Number(this.state.result.result["q40"]) +
      Number(this.state.result.result["q50"]) +
      Number(this.state.result.result["q60"]);

    this.setState({
      series: [
        {
          name: "Ergebnis",
          data: [
            stress,
            perfektion,
            delegation,
            ziele,
            prioritaeten,
            organisation,
            inselzeiten,
            besprechungen,
            organisation,
            arbeitsplanung,
          ],
        },
      ],
    });
  }

  generateLegendColor(nr) {
    let targetClass = "legend-red";
    if (nr >= 10 && nr <= 15) {
      targetClass = "legend-orange";
    } else if (nr > 15 && nr < 21) {
      targetClass = "legend-yellow";
    } else if (nr >= 21) {
      targetClass = "legend-green";
    }
    return (
      <span>
        <div className={targetClass}></div>
      </span>
    );
  }

  componentDidMount() {
    if (this.state.result) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .result("selbstmngmnt", this.props.authUser.uid)
      .on("value", (snapshot) => {
        this.setState({
          result: snapshot.val(),
          loading: false,
        });
        this.state.result && this.calculateEvaluation();
      });
  }

  componentWillUnmount() {
    this.props.firebase.result("selbstmngmnt", this.props.authUser.uid).off();
  }

  render() {
    const { result, loading } = this.state;

    return (
      <div>
        {loading && <div>...</div>}

        {result && (
          <div>
            {/*<div className="chart">*/}
            {/*  <Chart*/}
            {/*    options={this.state.options}*/}
            {/*    series={this.state.series}*/}
            {/*    type="radar"*/}
            {/*  />*/}
            {/*</div>*/}
            <div style={{ textAlign: "center", padding: "2rem 0rem" }}>
              <button
                style={{ padding: "12px 0" }}
                onClick={() => {
                  try {
                    document.execCommand("print", false, null);
                  } catch {
                    window.print();
                  }
                }}
              >
                ERGEBNISSE AUSDRUCKEN/HERUNTERLADEN ↗
              </button>
            </div>
            <div className="main-col">
              <table className="table-selbst-result">
                <thead>
                  <tr>
                    <th>Kategorie</th>
                    <th>Ergebnis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[0])
                      )}
                      Umgang mit Stress
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span>{this.state.series[0].data[0]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[1])
                      )}
                      Einstellung zur Perfektion
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span>{this.state.series[0].data[1]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[2])
                      )}
                      Delegation
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span>{this.state.series[0].data[2]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[3])
                      )}
                      Ziele setzen
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span> {this.state.series[0].data[3]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[4])
                      )}
                      Prioritäten setzen
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span>{this.state.series[0].data[4]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[5])
                      )}
                      Organisation und Struktur
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span>{this.state.series[0].data[5]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[6])
                      )}
                      Inselzeiten
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span>{this.state.series[0].data[6]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[7])
                      )}
                      Besprechungen und Gespräche
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span>{this.state.series[0].data[7]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[8])
                      )}
                      Informationskultur
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span>{this.state.series[0].data[8]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-much-text grid-center">
                      {this.generateLegendColor(
                        Number(this.state.series[0].data[9])
                      )}
                      Arbeitsplanung
                    </td>
                    <td className="table-much-text grid-spacing">
                      <span> {this.state.series[0].data[9]}</span>
                      <span>Punkte</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>Legende</h2>
              <table className="table-selbst">
                <thead>
                  <tr>
                    <th>Ergebnis</th>
                    <th>Bewertung</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="grid-center">
                      <span>
                        <div className="legend-red"></div>
                      </span>
                      <span>0 bis 9</span>
                    </td>

                    <td className="table-much-text">
                      Sie können Ihren Umgang mit der Zeit in dieser Kategorie
                      wesentlich effektiver gestalten. Fangen Sie sofort an.
                    </td>
                  </tr>
                  <tr>
                    <td className="grid-center">
                      <span>
                        <div className="legend-orange"></div>
                      </span>
                      <span>10 bis 15</span>
                    </td>

                    <td className="table-much-text">
                      Sie haben viele Möglichkeiten Ihren Umgang mit der Zeit in
                      dieser Kategorie zu optimieren. Nutzen Sie sie.
                    </td>
                  </tr>
                  <tr>
                    <td className="grid-center">
                      <span>
                        <div className="legend-yellow"></div>
                      </span>
                      <span>16 bis 20</span>
                    </td>

                    <td className="table-much-text">
                      Sie befinden sich im Durchschnitt in dieser Kategorie.
                      Wenn Sie zu denen gehören möchten, die mit Ihrer Zeit
                      besonders gut umgehen können, konzentrieren Sie sich auf
                      die Kategorien mit der geringsten Punktzahl.
                    </td>
                  </tr>
                  <tr>
                    <td className="grid-center">
                      <span>
                        <div className="legend-green"></div>
                      </span>
                      <span>21 bis 24</span>
                    </td>

                    <td className="table-much-text">
                      Ein Lob für Ihr Zeitmanagement in dieser Kategorie. <br />
                      Ob Sie im Detail noch etwas verbessern können? Lassen Sie
                      sich überraschen.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(SelbstmanagementResult);
