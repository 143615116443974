import React, { Component } from "react";

import { withFirebase } from "../Firebase";

import Chart from "react-apexcharts";

class StilFlexibilitaet extends Component {
  evalTable = {
    situation1: {
      A: "S1",
      B: "S4",
      C: "S2",
      D: "S3",
    },
    situation2: {
      A: "S1",
      B: "S3",
      C: "S2",
      D: "S4",
    },
    situation3: {
      A: "S1",
      B: "S2",
      C: "S3",
      D: "S4",
    },
    situation4: {
      A: "S4",
      B: "S2",
      C: "S1",
      D: "S3",
    },
    situation5: {
      A: "S2",
      B: "S4",
      C: "S3",
      D: "S1",
    },
    situation6: {
      A: "S1",
      B: "S4",
      C: "S2",
      D: "S3",
    },
    situation7: {
      A: "S3",
      B: "S1",
      C: "S4",
      D: "S2",
    },
    situation8: {
      A: "S3",
      B: "S2",
      C: "S1",
      D: "S4",
    },
    situation9: {
      A: "S3",
      B: "S2",
      C: "S4",
      D: "S1",
    },
    situation10: {
      A: "S4",
      B: "S1",
      C: "S2",
      D: "S3",
    },
    situation11: {
      A: "S4",
      B: "S1",
      C: "S2",
      D: "S3",
    },
    situation12: {
      A: "S1",
      B: "S3",
      C: "S2",
      D: "S4",
    },
    situation13: {
      A: "S4",
      B: "S1",
      C: "S2",
      D: "S3",
    },
    situation14: {
      A: "S3",
      B: "S2",
      C: "S4",
      D: "S1",
    },
    situation15: {
      A: "S1",
      B: "S3",
      C: "S2",
      D: "S4",
    },
    situation16: {
      A: "S4",
      B: "S1",
      C: "S3",
      D: "S2",
    },
    situation17: {
      A: "S3",
      B: "S4",
      C: "S1",
      D: "S2",
    },
    situation18: {
      A: "S2",
      B: "S1",
      C: "S3",
      D: "S4",
    },
    situation19: {
      A: "S3",
      B: "S2",
      C: "S4",
      D: "S1",
    },
    situation20: {
      A: "S2",
      B: "S4",
      C: "S3",
      D: "S1",
    },
  };

  stilMapping = {
    S1: "Dirigieren (S1)",
    S2: "Trainieren (S2)",
    S3: "Partizipieren (S3)",
    S4: "Delegieren (S4)",
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      result: null,
      columnSums: {
        S1: 0,
        S2: 0,
        S3: 0,
        S4: 0,
      },
      stilflex: 0,
      primStil: {
        stilString: "",
        counts: [],
      },
      sekStil: {
        stilString: "",
        counts: [],
      },
      entwickelStil: {
        stilString: "",
        counts: [],
      },
      options: {
        chart: {
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#5D5C5C",
              strokeWidth: "100%",
              margin: 5, // margin is in pixels
              opacity: 0.2,
              dropShadow: {
                enabled: false,
              },
            },
            dataLabels: {
              name: {
                show: true,
                color: "var(--font-color)",
                fontSize: "1rem",
                fontFamily: "Red Hat Text, sans-serif",
                fontWeight: 300,
                offsetY: -30,
              },
              value: {
                offsetY: -70,
                color: "white",
                fontSize: "1rem",
                fontFamily: "Red Hat Text, sans-serif",
                formatter: function (val) {
                  return val.toFixed(0) + " %";
                },
              },
            },
          },
        },
        grid: {
          padding: {
            top: -40,
          },
        },
        fill: {
          type: "solid",
        },
        labels: ["STILFLEXIBILITÄT"],
        colors: ["var(--border-color)"],
      },
      series: [0, 0],
    };
  }

  calculateResult() {
    // Stilflexibilitaet
    var columnSums = {
      S1: 0,
      S2: 0,
      S3: 0,
      S4: 0,
    };

    for (var situation in this.state.result.result) {
      columnSums[
        this.evalTable[situation][this.state.result.result[situation]]
      ]++;
    }

    this.setState({ columnSums: columnSums });

    var sumOfColumnSums = 0;
    for (var sum in columnSums) {
      sumOfColumnSums += Math.abs(columnSums[sum] - 5);
    }

    var stilFlex = Math.abs(sumOfColumnSums - 30);

    this.setState({
      stilflex: stilFlex,
      series: [(stilFlex / 30) * 100],
    });

    // Primärer Führungsstil
    var keys = Object.keys(columnSums);
    var largest = Math.max.apply(
      null,
      keys.map((x) => columnSums[x])
    );
    var largestKeys = keys.reduce((largestKeys, key) => {
      if (columnSums[key] === largest) {
        largestKeys.push(key);
      }
      return largestKeys;
    }, []);

    var primStilString = "";
    var primStilCounts = [];
    for (var stil in largestKeys) {
      primStilString += this.stilMapping[largestKeys[stil]] + ", ";
      primStilCounts.push(largest);
    }

    if (primStilString === "") {
      primStilString = "--";
    } else {
      primStilString = primStilString.slice(0, -2);
    }

    this.setState({
      primStil: {
        stilString: primStilString,
        counts: primStilCounts,
      },
    });

    var sekStilString = "";
    var sekStilCounts = [];
    // Sekundärer Führungsstil
    for (stil in columnSums) {
      if (columnSums[stil] !== largest && columnSums[stil] >= 4) {
        sekStilString += this.stilMapping[stil] + ", ";
        sekStilCounts.push(columnSums[stil]);
      }
    }

    if (sekStilString === "") {
      sekStilString = "--";
    } else {
      sekStilString = sekStilString.slice(0, -2);
    }

    this.setState({
      sekStil: {
        stilString: sekStilString,
        counts: sekStilCounts,
      },
    });

    var entwickelStilString = "";
    var entwickelStilCounts = [];
    // Entwicklungsfähiger Führungsstil
    for (stil in columnSums) {
      if (columnSums[stil] < 4) {
        entwickelStilString += this.stilMapping[stil] + ", ";
        entwickelStilCounts.push(columnSums[stil]);
      }
    }

    if (entwickelStilString === "") {
      entwickelStilString = "--";
    } else {
      entwickelStilString = entwickelStilString.slice(0, -2);
    }

    this.setState({
      entwickelStil: {
        stilString: entwickelStilString,
        counts: entwickelStilCounts,
      },
    });
  }

  componentDidMount() {
    if (this.state.result) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .result("fstil", this.props.authUser.uid)
      .on("value", (snapshot) => {
        this.setState({
          result: snapshot.val(),
          loading: false,
        });
        this.state.result && this.calculateResult();
      });
  }

  componentWillUnmount() {
    this.props.firebase.result("fstil", this.props.authUser.uid).off();
  }

  render() {
    const { result, loading } = this.state;

    return (
      <div>
        <div>{loading && <div>...</div>}</div>
        <div>
          {result && (
            <div>
              <div className="row result-box">
                <div className="halfcol-right halfdiv">
                  <table className="table-r-align table-fstil">
                    <tbody>
                      <tr>
                        <th>Führungsstil</th>
                        <th>Antworten</th>
                      </tr>
                      <tr>
                        <td>{this.stilMapping.S1}</td>
                        <td>{this.state.columnSums.S1}</td>
                      </tr>
                      <tr>
                        <td>{this.stilMapping.S2}</td>
                        <td>{this.state.columnSums.S2}</td>
                      </tr>
                      <tr>
                        <td>{this.stilMapping.S3}</td>
                        <td>{this.state.columnSums.S3}</td>
                      </tr>
                      <tr>
                        <td>{this.stilMapping.S4}</td>
                        <td>{this.state.columnSums.S4}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="halfcol halfdiv">
                  <table className="table-fstil">
                    {this.state.primStil !== "" && (
                      <tr>
                        <td>
                          {this.state.primStil.stilString.includes(", ")
                            ? "Meine primären Führungsstile"
                            : "Mein primärer Führungsstil"}
                        </td>
                        <td>{this.state.primStil.stilString}</td>
                      </tr>
                    )}
                    {this.state.sekStil !== "" && (
                      <tr>
                        <td>
                          {this.state.sekStil.stilString.includes(", ")
                            ? "Meine sekundären Führungsstile"
                            : "Mein sekundärer Führungsstil"}
                        </td>
                        <td>{this.state.sekStil.stilString}</td>
                      </tr>
                    )}
                    {this.state.entwickelStil !== "" && (
                      <tr className="font-colored">
                        <td>
                          {this.state.entwickelStil.stilString.includes(", ")
                            ? "Meine entwicklungsfähigen Führungsstile"
                            : "Mein entwicklungsfähiger Führungsstil"}
                        </td>
                        <td>{this.state.entwickelStil.stilString}</td>
                      </tr>
                    )}
                  </table>
                </div>
                <div className="m-auto">
                  <button
                    onClick={() => {
                      try {
                        document.execCommand("print", false, null);
                      } catch {
                        window.print();
                      }
                    }}
                  >
                    Ergebnisse ausdrucken ↗
                  </button>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "2rem" }}>
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="radialBar"
                  height="500"
                />
              </div>
              <div className="row">
                <div className="halfcol">
                  <h3>Primärer Führungsstil</h3>
                  Der Führungsstil mit den meisten Punkten bezeichnet Ihren
                  primären Führungsstil. Das ist der Führungsstil, den Sie nach
                  Ihren Angaben in dieser Analyse in verschiedenen
                  Führungssituationen am häufigsten wählen und präferieren. Das
                  können durchaus auch zwei Stile sein, die die identische hohe
                  Punktzahl erhalten haben.
                  <h3>Entwicklungsfähiger Führungsstil</h3>
                  Ein Stil mit weniger als vier Punkten bezeichnet Ihren
                  entwicklungsfähigen Führungsstil. Das ist der Führungsstil,
                  auf den Sie (noch) zu selten zurückgreifen und den Sie
                  vielleicht zukünftig weiter ausbauen und entwickeln möchten.
                  Der entwicklungsfähige Führungsstil kann - muss aber nicht -
                  bei Ihnen vorhanden sein.
                </div>
                <div className="halfcol">
                  <h3>Sekundärer Führungsstil</h3>
                  <p>
                    Ein weiterer Stil, der von Ihnen vier oder mehr Punkte
                    bekommen hat, bezeichnet Ihren sekundären Führungsstil.
                    Diesen Führungsstil wählen Sie häufig dann, wenn Sie der
                    Meinung sind, dass Ihr primärer Führungsstil weniger wirksam
                    ist. Der sekundäre Stil ist sozusagen Ihr
                    „Ersatzführungsstil“, auf den Sie in gewissen Situationen
                    zurückgreifen. Der sekundäre Führungsstil kann - muss aber
                    nicht - bei Ihnen vorhanden sein.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withFirebase(StilFlexibilitaet);
