import React, { Component } from "react";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";

import * as Survey from "survey-react";
import * as ROUTES from "../../constants/routes";

import surveyData from "./surveyData.json";

class SelbstmanagementSurvey extends Component {
  constructor(props) {
    super(props);

    var model = new Survey.Model(surveyData);

    this.jsonResult = surveyData;

    for (var page in this.jsonResult.pages) {
      this.jsonResult.pages[page].questions.push({
        type: "html",
        name: "info",
        html: '<br><br><p><a href="/testselbstmanagementresult">Zum Ergebnis ↗</a><p>',
      });
    }

    this.state = {
      model: model,
      loading: false,
      result: null,
    };
  }

  surveyCss = {
    root: "",
    pageTitle: "h2",
    footer: "sv_nav",
    navigation: {
      complete: "sv_complete_btn",
      prev: "sv_prev_btn",
      next: "sv_next_btn",
    },
    question: {
      title: "sv_q_title",
    },
    rating: {
      root: "sv_q_rating",
      item: "sv_q_rating_item",
      selected: "active",
      minText: "sv_q_rating_min_text",
      itemText: "sv_q_rating_item_text",
      maxText: "sv_q_rating_max_text",
      disabled: "",
    },
    error: {
      root: "sv_q_erbox",
      icon: "",
      item: "",
      locationTop: "sv_qstn_error_top",
      locationBottom: "sv_qstn_error_bottom",
    },
    navigationButton: "btn-lg",
  };

  jsonResult = {};

  onComplete(survey, options) {
    //Write survey results into database
    console.log("Survey results: " + JSON.stringify(survey.data));

    survey.props.firebase.result("selbstmngmnt", survey.authUser.uid).set({
      result: survey.data,
      createdAt: survey.props.firebase.serverValue.TIMESTAMP,
    });

    survey.props.history.push(ROUTES.TEST_SELBSTMANAGEMENT_RESULT);
  }

  componentDidMount() {
    if (this.state.result) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .result("selbstmngmnt", this.props.authUser.uid)
      .on("value", (snapshot) => {
        this.setState({
          result: snapshot.val(),
          loading: false,
        });

        if (this.state.result) {
          var survey = new Survey.Model(this.jsonResult);
          survey.data = this.state.result.result;
          survey.mode = "display";
          this.setState({ model: survey });
        }
      });
  }

  componentWillUnmount() {
    this.props.firebase.result("selbstmngmnt", this.props.authUser.uid).off();
  }

  render() {
    const { result, loading } = this.state;

    return (
      <div>
        {loading && <div>...</div>}
        <AuthUserContext.Consumer>
          {(authUser) => (
            <Survey.Survey
              props={this.props}
              authUser={authUser}
              model={this.state.model}
              css={this.surveyCss}
              onComplete={this.onComplete}
            />
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default withFirebase(SelbstmanagementSurvey);
