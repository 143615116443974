import React, {Component} from 'react';

import {withFirebase} from '../Firebase';

class PersoenlichkeitResult extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      result: null,
      evaluation:
      {
        macher: 0,
        kommunikator: 0,
        stabilisator: 0,
        analytiker: 0
      }
    };
  }

  calculateEvaluation() {
    var macher = 0;
    var kommunikator = 0;
    var stabilisator = 0;
    var analytiker = 0;

    for (var w in this.state.result.result) {
      macher += Math.abs(this.state.result.result[w].indexOf("M")-3);
      kommunikator += Math.abs(this.state.result.result[w].indexOf("K")-3);
      stabilisator += Math.abs(this.state.result.result[w].indexOf("S")-3);
      analytiker += Math.abs(this.state.result.result[w].indexOf("A")-3);
    }
    this.setState({
      evaluation:
      {
        macher: macher,
        kommunikator: kommunikator,
        stabilisator: stabilisator,
        analytiker: analytiker
      }
    })
  }

  componentDidMount() {
    if (this.state.result) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .result("persoenlichkeit", this.props.authUser.uid)
      .on('value', snapshot => {
        this.setState({
          result: snapshot.val(),
          loading: false,
        });
        this.state.result && this.calculateEvaluation();
      });
  }

  componentWillUnmount() {
    this.props.firebase.result("persoenlichkeit", this.props.authUser.uid).off();
  }


  render() {
    const { result, loading } = this.state;

    return (
      <div>
        {loading && <div>...</div>}

        {result && (
          <div>
            {/*<h3>Meine Antworten</h3>*/}
            <table className="pers-table">
              <thead>
                <tr>
                  <th>Kategorie</th>
                  <th>Ergebnis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Macher</td>
                  <td>{this.state.evaluation.macher}</td>
                </tr>
                <tr>
                  <td>Kommunikator</td>
                  <td>{this.state.evaluation.kommunikator}</td>
                </tr>
                <tr>
                  <td>Stabilisator</td>
                  <td>{this.state.evaluation.stabilisator}</td>
                </tr>
                <tr>
                  <td>Analytiker</td>
                  <td>{this.state.evaluation.analytiker}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(PersoenlichkeitResult);
