import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';

import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAak7mOb8vn-ZUvL7NvQRTOBEbflGS_fhs',
  authDomain: 'team-gold-surveys.firebaseapp.com',
  databaseURL: 'https://team-gold-surveys.firebaseio.com/',
  projectId: 'team-gold-surveys',
  storageBucket: 'gs://team-gold-surveys.appspot.com',
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    app.auth().languageCode = 'de';
    this.db = app.database();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();

    /* Cloud Functions */
    this.functions = firebase.app().functions('europe-west3');

    /* Cloud Storage */
    this.storage = firebase.storage();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser?.roles) {
              dbUser.roles = {};
            }

            if (!dbUser?.tests) {
              dbUser.tests = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');

  // *** Results API ***

  result = (testname, uid) =>  this.db.ref(`results/${testname}/${uid}`);

  results = () => this.db.ref('results');

  // *** Functions API ***

  addUser = user => {
    console.log("Calling cloud function...")
    const addUserCallable = this.functions.httpsCallable('addUser');
    return addUserCallable(user)
      .catch(function(error) {
        return Promise.reject({message: "Benutzer hinzufügen fehlgeschlagen."})
    });
  }

  changeUser = user => {
    console.log("Calling cloud function...")
    const changeUserCallable = this.functions.httpsCallable('changeUser');
    return changeUserCallable(user);
  }

  changeTests = user => {
    console.log("Calling cloud function...")
    const changeTestsCallable = this.functions.httpsCallable('changeTestsForUid');
    return changeTestsCallable(user);
  }

  deleteUser = user => {
    console.log("Calling cloud function...")
    const deleteUserCallable = this.functions.httpsCallable('deleteUser');
    return deleteUserCallable(user);
  }

  sendWelcomeEmail = user => {
    console.log("Calling cloud function...")
    const sendWelcomEmailCallable = this.functions.httpsCallable('sendWelcomeEmailUid');
    return sendWelcomEmailCallable(user);
  }
}

export default Firebase;
