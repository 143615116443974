import React, { Component } from "react";
import { compose } from "recompose";

import { AuthUserContext, withAuthorization } from "../Session";
import PersoenlichkeitResult from "./PersoenlichkeitResult";
import teamGoldLogo from "../../images/team_gold_logo.png";
import verhaltensTyp from "../../images/verhaltenstyp.png";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import LinkContainer from "../Utils/LinkContainer";

class TestPersoenlichkeitResultPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadUrl: "",
    };
  }

  componentDidMount() {
    this.props.firebase.storage
      .ref("Profilo_Workbook_Menschenkenntnis.pdf")
      .getDownloadURL()
      .then((url) => {
        this.setState({
          downloadUrl: url,
        });
      });
  }
  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="main-col">
            <div className="header-top row">
              <Link to={ROUTES.MEINE_PROFILE}>
                <img className="logo-right" src={teamGoldLogo} alt="Logo" />
              </Link>
            </div>
            <div className="row">
              <div className="header-main">
                <h1>Persönlichkeit und Menschenkenntnis</h1>
                <h2>Ergebnisse</h2>
              </div>
              <div className="header-right">
                <Link to={ROUTES.MEINE_PROFILE}>
                  <img className="logo-right" src={teamGoldLogo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="row result-box pers-result-box">
              <PersoenlichkeitResult authUser={authUser} />
              <div>
                <p>
                  Ein für andere im Umfeld schon spürbar typgeprägtes Verhalten
                  beginnt ab ca. 23 Punkten.
                </p>
                <div>
                  <button
                    style={{ padding: "12px 0" }}
                    onClick={() => {
                      try {
                        document.execCommand("print", false, null);
                      } catch {
                        window.print();
                      }
                    }}
                  >
                    ERGEBNISSE AUSDRUCKEN/HERUNTERLADEN ↗
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <h3 className="font-colored">Der Macher</h3>
              <div className="body-small">
                Möchte seine Ziele unbedingt erreichen. Möchte etwas „bewegen“.
                Geht Risiken ein. Trifft gerne eigene Entscheidungen. Geht
                Konflikten nicht aus dem Weg. Ändert die Regeln. Ist gerne der
                „Sieger“, ändert gerne.
              </div>
              <h3 className="font-colored">Der Kommunikator</h3>
              <div className="body-small">
                Will ausdrückliche Anerkennung. Begeistert andere, „zieht“ sie
                mit. Sehr kommunikativ, drückt sich gut aus. Braucht
                Abwechslung. Kann eigene Gefühle offen zeigen. Geht aktiv auf
                Menschen zu. Steht gerne im „Mittelpunkt“, will Neues.
              </div>
              <h3 className="font-colored">Der Analytiker</h3>
              <div className="body-small">
                Möchte hohe Qualität erreichen. Oft hoher Gerechtigkeitssinn.
                Stark und ausdauernd in der Analyse. Trifft wohlüberlegte
                Entscheidungen. Bevorzugt geregelte Umgebung. Diplomatisch und
                sehr guter Zuhörer. Ist gerne der Experte, Spezialist.
              </div>
              <h3 className="font-colored">Der Stabilisator</h3>
              <div className="body-small">
                Möchte mit anderen gut zusammenarbeiten. Mag ein sicheres,
                voraussagbares Umfeld. Sehr guter und gesuchter Zuhörer. Möchte
                anderen helfen. Denkt gründlich nach und handelt dann. Kann
                aufgeregte Leute gut beruhigen. Ist ein Teamplayer, unterstützt
                andere.
              </div>
            </div>
            <div className="row">
              <img
                className="img-fullsize-small"
                src={verhaltensTyp}
                alt="Logo"
              />
            </div>
            <div className="row">
              <div id="body" style={{ padding: "20px" }}>
                <h2>Links</h2>
                <LinkContainer>
                  <Link to={ROUTES.TEST_PERSOENLICHKEIT}>
                    Meine Antworten ↗
                  </Link>
                  <br />
                  <br />
                  <a href={this.state.downloadUrl} target="_blank">
                    Download: Erklärungen zum Persönlichkeitsmodell ↗
                  </a>
                  <br />
                </LinkContainer>
              </div>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => authUser && !!authUser.tests.includes("test2");

export default compose(withAuthorization(condition))(
  TestPersoenlichkeitResultPage
);
