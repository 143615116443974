import React from "react";
import * as TESTS from "../../constants/tests";
import { Link } from "react-router-dom";

// import { withFirebase } from '../Firebase';
import { AuthUserContext } from "../Session";

function TestList(props) {
  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          {authUser.tests &&
            authUser.tests.map((test) => (
              <p key={test}>
                <Link to={TESTS.tests[test].route}>
                  {TESTS.tests[test].fullName} ↗
                </Link>
              </p>
            ))}
          {authUser.tests.length == 0 ? (
            <p>
              Hier erscheinen in Kürze die für Sie freigeschalteten Profile.
            </p>
          ) : (
            ""
          )}
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

export default TestList;
