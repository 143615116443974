import React, {Component} from 'react';

import {withFirebase} from '../Firebase';
import {AuthUserContext} from '../Session';
import * as Survey from "survey-react";
import * as ROUTES from '../../constants/routes';

import surveyData from './surveyData.json'
import surveyDataWithAnswers from './surveyDataWithAnswers.json'

class FuehrungsstilSurvey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: new Survey.Model(surveyData),
      loading: false,
      result: null
    }
  }

  surveyCss = {
    root: "",
    pageTitle: "h2",
    footer: "sv_nav",
    navigation: {
      complete: "sv_complete_btn",
      prev: "sv_prev_btn",
      next: "sv_next_btn",
    },
    question: {
      title: "sv_q_title",
    },
    radiogroup: {
      root: "sv_qcbc",
      item: "sv_q_radiogroup",
      itemChecked: "checked",
      itemInline: "sv_q_radiogroup_inline",
      itemDecorator: "sv_hidden",
      label: "container",
      labelChecked: "",
      itemControl: "sv_q_radiogroup_control_item",
      controlLabel: "",
      materialDecorator: "checkmark",
      other: "sv_q_other sv_q_radiogroup_other",
      clearButton: "sv_q_radiogroup_clear",
      column: "sv_q_select_column",
    },
    error: {
      root: "sv_q_erbox",
      icon: "",
      item: "",
      locationTop: "sv_qstn_error_top",
      locationBottom: "sv_qstn_error_bottom",
    },
    navigationButton: "btn-lg"
  };

  onComplete(survey, options) {
    //Write survey results into database
    console.log("Survey results: " + JSON.stringify(survey.data));

    survey.props.firebase.result("fstil",survey.authUser.uid).set({
      result: survey.data,
      createdAt: survey.props.firebase.serverValue.TIMESTAMP,
    });

    survey.props.history.push(ROUTES.TEST_FUEHRUNGSSTIL_RESULT)

  }

  componentDidMount() {
    if (this.state.result) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .result("fstil", this.props.authUser.uid)
      .on('value', snapshot => {
        this.setState({
          result: snapshot.val(),
          loading: false,
        });
        if (this.state.result) {
          var survey = new Survey.Model(surveyDataWithAnswers);
          survey.data = this.state.result.result;
          survey.mode = "display";
          this.setState({model: survey})
        }
      });
  }

  componentWillUnmount() {
    this.props.firebase.result("fstil", this.props.authUser.uid).off();
  }


  render() {
    const { result, loading } = this.state;

    return (
      <div>
      {loading && <div>...</div>}
      <AuthUserContext.Consumer>
        {authUser => (
          <Survey.Survey props={this.props} authUser={authUser} model={this.state.model} css={this.surveyCss} onComplete={this.onComplete} />
        )}
      </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default withFirebase(FuehrungsstilSurvey);
