import React, {Component} from 'react';

import {withFirebase} from '../Firebase';
import * as TESTS from '../../constants/tests';
import * as ROUTES from '../../constants/routes';
import {generatePassword} from '../Admin'
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de"; // the locale you want
import {subDays} from "date-fns";

registerLocale("de", de); // register it with the name you want


const INITIAL_STATE = {
  user: null,
  loading: false,
  username: '',
  email: '',
  password: '',
  isAdmin: false,
  generatePassword: false,
  tests: [],
  error: null,
  sendWelcomeDate: null,
  welcomeSentDate: null
};

class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .user(this.props.match.params.id)
      .on('value', snapshot => {
        var user = snapshot.val()
        this.setState({
          user: user,
          username: user.username,
          email: user.email,
          isAdmin: (user.roles && user.roles["ADMIN"] === "ADMIN") ? true : false,
          tests: user.tests,
          loading: false,
          sendWelcomeNow: true,
          sendWelcomeDate: user.sendWelcomeDate == "now" ? user.sendWelcomeDate : (user.sendWelcomeDate ? new Date(user.sendWelcomeDate) : null),
          welcomeSentDate: user.welcomeSentDate
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();

    for (var test in this.state.tests) {
      var testid = this.state.tests[test]
      this.props.firebase
      .result(TESTS.tests[testid].dbName, this.props.match.params.id).off()
    }

  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    if (event.target.name.startsWith("generatePassword")) {
      if (event.target.checked) {
        this.setState({password: ''})
      }
    }
    if (event.target.name.startsWith("test")) {
      event.persist();
      if (event.target.checked) {
        this.setState(state => {
          const newTests = state.tests ? state.tests.concat(event.target.name) : [event.target.name];
          return {
              tests: newTests
          };
        });
      }
      else {
        this.setState(state => {
          const newTests = state.tests.filter(test => test !== event.target.name);
          return {
              tests: newTests
          };
        });
      }

    }
    else {
      this.setState({ [event.target.name]: event.target.checked });
    }
  };

  onClickSaveButton = event => {

    var password = this.state.generatePassword ? generatePassword() : this.state.password

    var sendWelcomeDate = this.state.sendWelcomeNow && (this.state.password || this.state.generatePassword) ?
      "now" :
      (this.state.sendWelcomeDate ?
        this.state.sendWelcomeDate.toJSON() :
        null)

    const user = {
      uid: this.props.match.params.id,
      name: this.state.username,
      email: this.state.email,
      password: password,
      admin: this.state.isAdmin,
      tests: this.state.tests,
      sendWelcomeDate: sendWelcomeDate,
      welcomeSentDate: this.state.welcomeSentDate
    }

    this.props.firebase.changeUser(user);
    this.props.history.push(ROUTES.ADMIN);
  };

  onClickDeleteButton = event => {

    const user = {
      uid: this.props.match.params.id
    }

    this.props.firebase.deleteUser(user);
    this.props.history.push(ROUTES.ADMIN);
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.email);
  };

  onClickResetResults = event => {
    var test = event.target.name
    this.props.firebase.result(TESTS.tests[test].dbName, this.props.match.params.id).remove()
  }

  filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  }

  render() {
    const { loading, user, username, email, password, isAdmin, generatePassword, tests, error, sendWelcomeDate, welcomeSentDate, sendWelcomeNow } = this.state;

    const isInvalid =
      (!generatePassword && password.length > 0 && password.length < 8) ||
      email === '' ||
      username === '';

    return (
      <div>
        <h2>Benutzerdetails </h2>
        <p>ID: {this.props.match.params.id}</p>
        {loading && <div>...</div>}

        {user && (
          <div>
            <form onSubmit={this.onSubmit}>
              <p><input
                name="username"
                placeholder="Name"
                value={username}
                onChange={this.onChange}
                type="text"
              /><br />
              <input
                name="email"
                placeholder="E-Mail"
                value={email}
                onChange={this.onChange}
                type="text"
              /><br />
              <input
                name="password"
                placeholder="Passwort"
                value={password}
                onChange={this.onChange}
                disabled={generatePassword}
                type="password"
              />{(password.length > 0 && password.length < 8) && "⚠️ Passwort zu kurz!"}</p><p>
              <input
                  name="generatePassword"
                  type="checkbox"
                  checked={generatePassword}
                  onChange={this.onChangeCheckbox}
                /> Passwort automatisch generieren<br />
                <label>
                <input
                  name="isAdmin"
                  type="checkbox"
                  checked={isAdmin}
                  onChange={this.onChangeCheckbox}
                /> Benutzer ist Admininstrator
              </label></p>
              <h3>Willkommens-Email</h3>

                {(generatePassword || password) &&
                  <p>
                  <label>
                    <input
                      name="sendWelcomeNow"
                      type="checkbox"
                      checked={sendWelcomeNow}
                      onChange={this.onChangeCheckbox}
                    /> Willkommens-Email sofort verschicken
                  </label>
                  </p>
                }

              <p>Zeitpunkt zum Verschicken:&nbsp;
                <DatePicker
                  showTimeSelect
                  locale="de"
                  timeCaption="Zeit"
                  timeFormat="p"
                  timeIntervals={30}
                  dateFormat="dd.MM.yyyy HH:mm"
                  timeFormat="HH:mm"
                  filterDate = {(date) => {
                    return subDays(new Date(), 1) < date;
                  }}
                  filterTime={this.filterPassedTime}
                  selected={sendWelcomeDate == "now" ? null : sendWelcomeDate}
                  disabled={sendWelcomeNow || !(password || generatePassword)}
                  onChange={date => this.setState({sendWelcomeDate: date}) } />
              </p>
              <p>
                Willkommens-Email wird verschickt:&nbsp;
                {sendWelcomeDate == "now" ?
                  "Sofort" :
                  sendWelcomeDate ?
                    new Date(sendWelcomeDate).toLocaleDateString() + " " + new Date(sendWelcomeDate).toLocaleTimeString() :
                    "Nicht geplant"}<br />
                Willkommens-Email wurde verschickt:&nbsp;
                {welcomeSentDate == "never" ?
                  "Nie" :
                  welcomeSentDate ?
                    new Date(welcomeSentDate).toLocaleDateString() + " " + new Date(welcomeSentDate).toLocaleTimeString() :
                    "Nicht bekannt"}
              </p>
              <h3>Zugewiesene Tests</h3>
              {Object.keys(TESTS.tests).map((test) => {
                return <div key={test}>
                  <label>
                    <input name={test} type="checkbox" checked={tests && tests.includes(test)} onChange={this.onChangeCheckbox} />
                    {TESTS.tests[test].fullName}
                  </label> <a name={test} href="#reset" onClick={this.onClickResetResults}>Ergebnisse zurücksetzen</a>
                </div>
              })}
              <p><button disabled={isInvalid} onClick={this.onClickSaveButton} type="button">
                Speichern
              </button>
              <button disabled={isInvalid} onClick={this.onClickDeleteButton} type="button">
                Benutzer löschen
              </button>
              </p>


              {error && <p>{error.message}</p>}
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(UserItem);
