export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const MEINE_PROFILE = '/meineprofile';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const TEST_FUEHRUNGSSTIL_INTRO = '/testfuehrungsstilintro'
export const TEST_FUEHRUNGSSTIL = '/testfuehrungsstil'
export const TEST_FUEHRUNGSSTIL_RESULT = '/testfuehrungsstilresult'
export const TEST_SELBSTMANAGEMENT_INTRO = '/testselbstmanagementintro'
export const TEST_SELBSTMANAGEMENT = '/testselbstmanagement'
export const TEST_SELBSTMANAGEMENT_RESULT = '/testselbstmanagementresult'
export const TEST_PERSOENLICHKEIT_INTRO = '/testpersoenlichkeitintro'
export const TEST_PERSOENLICHKEIT = '/testpersoenlichkeit'
export const TEST_PERSOENLICHKEIT_RESULT = '/testpersoenlichkeitresult'
export const DATENSCHUTZ ='/privacy'
export const IMPRESSUM ='/imprint'