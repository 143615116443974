import React from "react";
import { compose } from "recompose";

import { AuthUserContext, withAuthorization } from "../Session";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import TestResultLinks from "./TestResultLinks";
import teamGoldLogo from "../../images/team_gold_logo.png";

const TestSelbstmanagementIntroPage = () => (
  <div className="main-col">
    <div className="header-top row">
      <Link to={ROUTES.MEINE_PROFILE}>
        <img className="logo-right" src={teamGoldLogo} alt="Logo" />
      </Link>
    </div>
    <div className="row">
      <div className="header-main">
        <h1>Selbst- und Zeitmanagement</h1>
        <h2>Zeit – was ist das?</h2>
        <p>
          Ein effektives Zeitmanagement wird in der heutigen Arbeitswelt immer
          wichtiger. Täglich sind Sie einer Flut von Informationen und daraus
          resultierenden Aufgaben ausgesetzt, die häufig kaum mehr zu bewältigen
          sind. Dabei basieren beruflicher Erfolg und persönliche Zufriedenheit
          zu einem wesentlichen Teil auf einer optimalen Zeit­planung und einer
          sinnvollen Zeiteinteilung.
        </p>
        <p>
          Mit einem auf die eigene Persönlichkeit angepassten Zeitmanagement,
          erledigen Sie die Dinge mit geringerem Zeitaufwand und behalten den
          Blick für das Wesentliche.
        </p>
        <p>
          Da es kein Zeitmanagement-System gibt, das für alle Menschen und
          Persönlichkeitstypen gleich geeignet wäre, erhalten Sie in diesem
          Profil mehrere Optionen. Die Struktur ist nach dem Baukastenprinzip
          konzipiert: Sie überprüfen Ihr eigenes Zeitmanagement und ergänzen es
          an notwendiger Stelle um den zu optimierenden Part. So erzielen Sie
          Wirksamkeit und Nachhaltigkeit.
        </p>
        <p>
          Fragen Sie sich dabei immer, was die Intention Ihres Handelns ist:
          Worum geht es Ihnen genau? Wollen Sie mehr Zeit, um noch mehr Arbeit
          erledigen zu können? Wollen Sie mehr Zeit für Ihr Privatleben
          generieren? Wollen Sie strukturierter arbeiten?
        </p>
        <p>
          Sie selbst sind der Dreh- und Angelpunkt Ihres eigenen
          Zeitmanagements. Prüfen Sie Ihre Motivation und Ihre Einstellung
          ehrlich und selbstkritisch.
        </p>
        <p>
          Bitte denken Sie daran, dass Sie Gewohnheiten, die Sie ein Leben lang
          „antrainiert“ haben, nicht innerhalb eines Tages ändern können. Ein
          solcher Prozess dauert länger. Haben Sie Geduld, Ausdauer und vor
          allem Disziplin.
        </p>
        <p>
          Disziplin ist ein Werkzeug und das wesentliche Instrument der
          Selbstführung. Man kann sie trainieren wie einen Muskel.
        </p>
        <p>Wir wünschen Ihnen dazu viel Erfolg!</p>
      </div>
      <div className="header-right">
        <Link to={ROUTES.MEINE_PROFILE}>
          <img className="logo-right" src={teamGoldLogo} alt="Logo" />
        </Link>
      </div>
    </div>
    <div className="row">
      <div id="body">
        <AuthUserContext.Consumer>
          {(authUser) => <TestResultLinks authUser={authUser} />}
        </AuthUserContext.Consumer>
      </div>
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(
  TestSelbstmanagementIntroPage
);
