import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import * as TESTS from "../../constants/tests";

import { generatePassword } from "../Admin";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de"; // the locale you want
import { subDays } from "date-fns";

registerLocale("de", de); // register it with the name you want

const SignUpPage = () => (
  <div>
    <h2>Benutzer hinzufügen</h2>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  password: "",
  isAdmin: false,
  sendWelcomeNow: true,
  generatePassword: true,
  tests: [],
  error: null,
  sendWelcomeDate: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onClickAddButton = (event) => {
    var password = this.state.generatePassword
      ? generatePassword()
      : this.state.password;

    const user = {
      name: this.state.username,
      email: this.state.email,
      password: password,
      admin: this.state.isAdmin,
      tests: this.state.tests,
      sendWelcomeDate: this.state.sendWelcomeDate
        ? this.state.sendWelcomeDate.toJSON()
        : this.state.sendWelcomeNow
        ? "now"
        : null,
    };

    this.props.firebase
      .addUser(user)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = (event) => {
    if (event.target.name.startsWith("generatePassword")) {
      if (event.target.checked) {
        this.setState({ password: "" });
      }
      this.setState({ [event.target.name]: event.target.checked });
    } else if (event.target.name.startsWith("test")) {
      event.persist();
      if (event.target.checked) {
        this.setState((state) => {
          const newTests = state.tests.concat(event.target.name);
          return {
            tests: newTests,
          };
        });
      } else {
        this.setState((state) => {
          const newTests = state.tests.filter(
            (test) => test !== event.target.name
          );
          return {
            tests: newTests,
          };
        });
      }
    } else if (
      event.target.name.startsWith("sendWelcomeNow") &&
      event.target.checked
    ) {
      this.setState({
        sendWelcomeDate: null,
        [event.target.name]: event.target.checked,
      });
    } else {
      this.setState({ [event.target.name]: event.target.checked });
    }
  };

  filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  render() {
    const {
      username,
      email,
      password,
      isAdmin,
      sendWelcomeNow,
      generatePassword,
      tests,
      error,
      sendWelcomeDate,
    } = this.state;

    const isInvalid =
      (!generatePassword && password.length < 8) ||
      email === "" ||
      username === "";

    return (
      <form onSubmit={this.onSubmit}>
        <p>
          <input
            name="username"
            placeholder="Name"
            value={username}
            onChange={this.onChange}
            type="text"
          />
          <br />
          <input
            name="email"
            placeholder="E-Mail"
            value={email}
            onChange={this.onChange}
            type="text"
          />
          <br />
          <input
            name="password"
            placeholder="Passwort"
            value={password}
            onChange={this.onChange}
            disabled={generatePassword}
            type="password"
          />
          {password.length > 0 && password.length < 8 && "⚠️ Passwort zu kurz!"}
        </p>
        <p>
          <input
            name="generatePassword"
            type="checkbox"
            checked={generatePassword}
            onChange={this.onChangeCheckbox}
          />{" "}
          Passwort automatisch generieren
          <br />
          <label>
            <input
              name="isAdmin"
              type="checkbox"
              checked={isAdmin}
              onChange={this.onChangeCheckbox}
            />{" "}
            Benutzer ist Admininstrator
          </label>
        </p>
        <h3>Zugewiesene Profile</h3>
        {Object.keys(TESTS.tests).map((test) => {
          return (
            <div key={test}>
              <label>
                <input
                  name={test}
                  type="checkbox"
                  checked={this.state.tests.includes(test)}
                  onChange={this.onChangeCheckbox}
                />{" "}
                {TESTS.tests[test].fullName}
              </label>
            </div>
          );
        })}

        <h3>Willkommens-Email</h3>
        <p>
          <label>
            <input
              name="sendWelcomeNow"
              type="checkbox"
              checked={sendWelcomeNow}
              onChange={this.onChangeCheckbox}
            />{" "}
            Willkommens-Email sofort verschicken
          </label>
        </p>
        <p>
          Zeitpunkt zum Verschicken:&nbsp;
          <DatePicker
            showTimeSelect
            locale="de"
            timeCaption="Zeit"
            timeFormat="p"
            timeIntervals={30}
            dateFormat="dd.MM.yyyy HH:mm"
            timeFormat="HH:mm"
            filterDate={(date) => {
              return subDays(new Date(), 1) < date;
            }}
            filterTime={this.filterPassedTime}
            selected={sendWelcomeDate}
            disabled={sendWelcomeNow}
            onChange={(date) => this.setState({ sendWelcomeDate: date })}
          />
        </p>
        <button
          disabled={isInvalid}
          onClick={this.onClickAddButton}
          type="button"
        >
          Hinzufügen
        </button>

        {error && <p>⚠️ {error.message}</p>}
      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
