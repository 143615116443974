import { compose } from "recompose";

import { AuthUserContext, withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import teamGoldLogo from "../../images/team_gold_logo.png";
import StilFlexibilitaet from "./StilFlexibilitaet";
import StilWirksamkeit from "./StilWirksamkeit";
import LinkContainer from "../Utils/LinkContainer";

class TestFuehrungsstilResultPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadUrl: "",
    };
  }

  componentDidMount() {
    this.props.firebase.storage
      .ref("Fuehrungsstilanalyse_desc2.pdf")
      .getDownloadURL()
      .then((url) => {
        this.setState({
          downloadUrl: url,
        });
      });
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="main-col">
            <div className="header-top row">
              <Link to={ROUTES.MEINE_PROFILE}>
                <img className="logo-right" src={teamGoldLogo} alt="Logo" />
              </Link>
            </div>
            <div className="row">
              <div className="header-main text-col">
                <h1>Führungsstil-Analyse</h1>
                <h2>Ergebnisse</h2>
                <h3 className="font-colored">
                  Auswertung der Stilflexibilität
                </h3>
                <p>
                  Als Führungskraft ist es wichtig, sich flexibel auf
                  verschiedene Situationen und Reifegrade der Mitarbeiter
                  einzustellen. Denn „den einen richtigen“ Führungsstil gibt es
                  nicht. Eine niedrige Bewertung bezeichnet geringe
                  Stilflexibilität und ist entstanden, weil Sie durchgehend nur
                  einen (oder zwei) Führungsstil(e) für die unterschiedlichen
                  Situationen ausgewählt haben. Eine hohe Bewertung bezeichnet
                  große Stilflexibilität und ist entstanden, weil Sie alle vier
                  Führungsstile mehrmals ausgewählt haben.
                </p>
              </div>
              <div className="header-right">
                <Link to={ROUTES.MEINE_PROFILE}>
                  <img className="logo-right" src={teamGoldLogo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="row">
              <StilFlexibilitaet authUser={authUser} />
            </div>
            <div className="row">
              <StilWirksamkeit authUser={authUser} />
            </div>
            <div className="row">
              <div id="body" style={{ padding: "20px" }}>
                <h2>Links</h2>
                <LinkContainer>
                  <Link to={ROUTES.TEST_FUEHRUNGSSTIL}>
                    Meine Antworten mit Bewertung ↗
                  </Link>
                  <a href={this.state.downloadUrl} target="_blank">
                    PDF-Download: Erklärung der Führungsstile ↗
                  </a>
                </LinkContainer>
              </div>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => authUser && !!authUser.tests.includes("test0");

export default compose(
  withAuthorization(condition),
  withFirebase
)(TestFuehrungsstilResultPage);
