import React from "react";
import { Link } from "react-router-dom";
import instagramLogo from "../../images/instagram.png";
import facebookLogo from "../../images/facebook.png";
import xingLogo from "../../images/xing.png";

import { AuthUserContext } from "../Session";
// import SignOutButton from '../SignOut';
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import { withFirebase } from "../Firebase";
import teamGoldLogo from "../../images/team_gold_logo.png";

const NavigationFooter = ({ firebase }) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <div>
          <NavigationAuth authUser={authUser} firebase={firebase} />
        </div>
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser, firebase }) => (
  <div id="footer" className="h-align">
    <div id="logo-container">
      <div id="footer-logo">
        <Link to={ROUTES.MEINE_PROFILE}>
          <img style={{ width: "100%" }} src={teamGoldLogo} alt="Logo" />
        </Link>
      </div>
      <Link to={ROUTES.MEINE_PROFILE} onClick={firebase.doSignOut}>
        LOGOUT ↗
      </Link>
    </div>
    <div id="footer-nav">
      <div>
        {!!authUser.roles[ROLES.ADMIN] && <Link to={ROUTES.ADMIN}>ADMIN</Link>}
      </div>
      <div>
        <Link className="footer-link" to={ROUTES.DATENSCHUTZ}>
          DATENSCHUTZ
        </Link>
      </div>
      <div>
        {" "}
        <Link to={ROUTES.MEINE_PROFILE}>MEINE PROFILE</Link>
      </div>
      <div>
        <Link className="footer-link" to={ROUTES.IMPRESSUM}>
          IMPRESSUM
        </Link>
      </div>
    </div>
    <div id="footer-socials">
      <div id="instagram" className="social">
        <Link
          to={{
            pathname: "https://www.instagram.com/team_gold_bayreuth/",
          }}
          target="_blank"
        >
          <img src={instagramLogo} alt="XING Logo" />
        </Link>
      </div>
      <div id="facebook" className="social">
        <Link
          to={{
            pathname: "https://www.facebook.com/gold.unternehmensentwicklung/",
          }}
          target="_blank"
        >
          <img src={facebookLogo} alt="XING Logo" />
        </Link>
      </div>
      <div id="xing" className="social">
        <Link
          to={{
            pathname:
              "https://www.xing.com/companies/teamgold-goldunternehmensentwicklunggmbh",
          }}
          target="_blank"
        >
          <img src={xingLogo} alt="XING Logo" />
        </Link>
      </div>
    </div>
  </div>
);

const NavigationNonAuth = () => <div id="footer" className="h-align"></div>;

export default withFirebase(NavigationFooter);
