import * as ROUTES from './routes';

export const tests = {
    test0: {
      fullName: "Führungsstil-Analyse",
      route: ROUTES.TEST_FUEHRUNGSSTIL_INTRO,
      dbName: "fstil"
    },
    test1: {
      fullName: "Selbst- und Zeitmanagement",
      route: ROUTES.TEST_SELBSTMANAGEMENT_INTRO,
      dbName: "selbstmngmnt"
    },
    test2: {
      fullName: "Persönlichkeit und Menschenkenntnis",
      route: ROUTES.TEST_PERSOENLICHKEIT_INTRO,
      dbName: "persoenlichkeit"
    }
  };