import React, { Component } from "react";
import { compose } from "recompose";

import { AuthUserContext, withAuthorization } from "../Session";
import SelbstmanagementResult from "./SelbstmanagementResult";
import teamGoldLogo from "../../images/team_gold_logo.png";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import LinkContainer from "../Utils/LinkContainer";

class TestSelbstmanagementResultPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadUrl: "",
    };
  }

  componentDidMount() {
    this.props.firebase.storage
      .ref("ZeitSelbstmanagement_desc.pdf")
      .getDownloadURL()
      .then((url) => {
        this.setState({
          downloadUrl: url,
        });
      });
  }
  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            <div className="main-col">
              <div className="header-top row">
                <Link to={ROUTES.MEINE_PROFILE}>
                  <img className="logo-right" src={teamGoldLogo} alt="Logo" />
                </Link>
              </div>
              <div className="row">
                <div className="header-main">
                  <h1>Selbst- und Zeitmanagement</h1>
                  <h2>Ergebnisse</h2>
                </div>
                <div className="header-right">
                  <Link to={ROUTES.MEINE_PROFILE}>
                    <img className="logo-right" src={teamGoldLogo} alt="Logo" />
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <SelbstmanagementResult authUser={authUser} />
            </div>

            <div className="main-col">
              <div id="body">
                <h2>Links</h2>
                <LinkContainer>
                  <Link to={ROUTES.TEST_SELBSTMANAGEMENT}>
                    Meine Antworten ↗
                  </Link>
                  <br />
                  <br />
                  <a href={this.state.downloadUrl} target="_blank">
                    Download: Erklärung der Kategorien ↗
                  </a>
                  <br />
                </LinkContainer>
              </div>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => authUser && !!authUser.tests.includes("test1");

export default compose(withAuthorization(condition))(
  TestSelbstmanagementResultPage
);
