import React, { Component } from "react";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";

import surveyData from "./surveyData.json";

import * as Survey from "survey-react";
import "survey-react/survey.css";

import "./index.css";

import * as widgets from "surveyjs-widgets";

import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";

class PersoenlichkeitSurvey extends Component {
  constructor(props) {
    super(props);
    widgets.sortablejs(Survey);

    this.state = {
      model: new Survey.Model(surveyData),
      loading: false,
      result: null,
    };

    this.state.model.onAfterRenderQuestion.add(function (survey, options) {
      //Return if there is no description to show in popup

      console.log(options.htmlElement);
      //Add a button;
      var btn = document.createElement("h3");
      // btn.type = "button";
      // btn.className = "btn btn-info btn-xs";

      // btn.style.position = "absolute";
      // btn.style.marginLeft = "20px"

      btn.innerHTML = "1.";
      var question = options.question;

      var header = options.htmlElement.querySelector(
        "div[class=sjs-sortablejs-root]"
      );

      var results = options.htmlElement.querySelector(
        "div[class=sjs-sortablejs-result]"
      );

      var table = document.createElement("table");
      table.className = "main-col";
      var tr = document.createElement("tr");
      var td = document.createElement("td");
      // span.innerHTML = "  ";
      var table_row1 = header.appendChild(table).appendChild(tr);
      var firstColumnTd = table_row1.appendChild(document.createElement("td"));
      firstColumnTd.className = "sortable-count-td";

      var firstColumn = firstColumnTd.appendChild(
        document.createElement("div")
      );
      // firstColumn.className = "sortable-count"

      var first = firstColumn.appendChild(document.createElement("div"));
      first.innerHTML = "<span>1</span><b>am ehesten</b>";
      first.className = "sortable-count";

      var second = firstColumn.appendChild(document.createElement("div"));
      second.innerHTML = "<span>2</span>";
      second.className = "sortable-count";

      var third = firstColumn.appendChild(document.createElement("div"));
      third.innerHTML = "<span>3</span>";
      third.className = "sortable-count";

      var fourth = firstColumn.appendChild(document.createElement("div"));
      fourth.innerHTML = "<span>4</span><b>am wenigsten</b>";
      fourth.className = "sortable-count";

      var secondColumnTd = table_row1.appendChild(document.createElement("td"));
      secondColumnTd.className = "sortable-result-td";
      secondColumnTd.appendChild(results);
    });
  }

  surveyCss = {
    root: "",
    pageTitle: "h2",
    footer: "sv_nav",
    navigation: {
      complete: "sv_complete_btn",
      prev: "sv_prev_btn",
      next: "sv_next_btn",
    },
    question: {
      title: "sv_q_title",
    },
    error: {
      root: "sv_q_erbox",
      icon: "",
      item: "",
      locationTop: "sv_qstn_error_top",
      locationBottom: "sv_qstn_error_bottom",
    },
    navigationButton: "btn-lg",
  };

  onComplete(survey, options) {
    //Write survey results into database
    console.log("Survey results: " + JSON.stringify(survey.data));

    survey.props.firebase.result("persoenlichkeit", survey.authUser.uid).set({
      result: survey.data,
      createdAt: survey.props.firebase.serverValue.TIMESTAMP,
    });

    survey.props.history.push(ROUTES.TEST_PERSOENLICHKEIT_RESULT);
  }

  componentDidMount() {
    if (this.state.result) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .result("persoenlichkeit", this.props.authUser.uid)
      .on("value", (snapshot) => {
        this.setState({
          result: snapshot.val(),
          loading: false,
        });
        var survey = this.state.model;
        if (this.state.result) {
          survey.data = this.state.result.result;
          survey.mode = "display";
          this.setState({ model: survey });
        } else {
          this.state.model.getAllQuestions().forEach(function (q) {
            if (q.getType() === "sortablelist") {
              q.value = q.choices.map(function (c) {
                return c.value;
              });
            }
          });
        }
      });
  }

  componentWillUnmount() {
    this.props.firebase
      .result("persoenlichkeit", this.props.authUser.uid)
      .off();
  }

  render() {
    const { result, loading } = this.state;

    return (
      <>
        <div id="survey-pers">
          {loading && <div>...</div>}

          <AuthUserContext.Consumer>
            {(authUser) => (
              <Survey.Survey
                props={this.props}
                authUser={authUser}
                model={this.state.model}
                css={this.surveyCss}
                onComplete={this.onComplete}
              />
            )}
          </AuthUserContext.Consumer>
        </div>
        {this.state.result && (
          <div className="to-result-pers">
            <Link to={ROUTES.TEST_PERSOENLICHKEIT_RESULT}>Zum Ergebnis ↗</Link>
          </div>
        )}
      </>
    );
  }
}

export default withFirebase(PersoenlichkeitSurvey);
