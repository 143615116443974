import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { compose } from "recompose";

import { withAuthorization } from "../Session";
import { UserItem, UserList } from "../Users";
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";
import SignUpPage from "../SignUp";

import teamGoldLogo from "../../images/team_gold_logo.png";

const AdminPage = () => (
  <div className="main-col">
    <div className="header-top row">
      <Link to={ROUTES.MEINE_PROFILE}>
        <img className="logo-right" src={teamGoldLogo} alt="Logo" />
      </Link>
    </div>
    <div className="row">
      <div className="header-main">
        <h1>Administration</h1>
      </div>
      <div className="header-right">
        <Link to={ROUTES.MEINE_PROFILE}>
          <img className="logo-right" src={teamGoldLogo} alt="Logo" />
        </Link>
      </div>
    </div>
    <div className="row">
      <div id="body">
        <SignUpPage />
        <br />
        <br />
        <Switch>
          <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
          <Route exact path={ROUTES.ADMIN} component={UserList} />
        </Switch>
      </div>
    </div>
  </div>
);

export const generatePassword = () => {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";

  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withAuthorization(condition))(AdminPage);
