import React from "react";
import { compose } from "recompose";

import { AuthUserContext, withAuthorization } from "../Session";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import teamGoldLogo from "../../images/team_gold_logo.png";
import TestResultLinks from "./TestResultLinks";

const TestFuehrungsstilIntroPage = () => (
  <div className="main-col">
    <div className="header-top row">
      <Link to={ROUTES.MEINE_PROFILE}>
        <img className="logo-right" src={teamGoldLogo} alt="Logo" />
      </Link>
    </div>
    <div className="row">
      <div className="header-main">
        <h1>Führungsstil-Analyse</h1>
        <h2>
          <p>Führungsstil – was ist das?</p>
        </h2>
        <p>
          Es gibt nicht den einen richtigen Führungsstil. Ihr Führungsverhalten
          hängt immer von der Situation ab. Ziel dieser Führungsstil-Analyse ist
          es, Ihnen eine Standortbestimmung darüber zu geben, wie wirksam und
          flexibel Sie führen.
        </p>
        <p>
          Nachfolgend werden Ihnen 20 Situationen mit jeweils vier Möglichkeiten
          beschrieben, wie Sie als Führungskraft reagieren könnten. Kreuzen Sie
          bitte jeweils den Buchstaben Ihrer Entscheidung an, von der Sie
          meinen, dass Sie Ihrem situativen Führungsverhalten am nähesten kommt.
        </p>
      </div>
      <div className="header-right">
        <Link to={ROUTES.MEINE_PROFILE}>
          <img className="logo-right" src={teamGoldLogo} alt="Logo" />
        </Link>
      </div>
    </div>
    <div className="row">
      <div id="body">
        <AuthUserContext.Consumer>
          {(authUser) => <TestResultLinks authUser={authUser} />}
        </AuthUserContext.Consumer>
      </div>
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(
  TestFuehrungsstilIntroPage
);
