import React from "react";
import { compose } from "recompose";

import { AuthUserContext, withAuthorization } from "../Session";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import TestResultLinks from "./TestResultLinks";

import teamGoldLogo from "../../images/team_gold_logo.png";

const TestPersoenlichkeitIntroPage = () => (
  <div className="main-col">
    <div className="header-top row">
      <Link to={ROUTES.MEINE_PROFILE}>
        <img className="logo-right" src={teamGoldLogo} alt="Logo" />
      </Link>
    </div>
    <div className="row">
      <div className="header-main">
        <h1>Persönlichkeit und Menschenkenntnis</h1>
        <h2>
          <p>Persönliches Verhaltensprofil</p>
        </h2>
        <p>
          Menschen sind verschieden. Nicht nur in der Art, wie sie sich geben
          und den Kontakt zu ihren Mitmenschen gestalten, sondern auch darin,
          was sie innerlich empfinden und brauchen, um sich im Gespräch und im
          zwischenmenschlichen Miteinander wohl zu fühlen. Um menschliche
          Unterschiede und ihre Auswirkungen auf Kommunikation und Beziehungen
          zu verstehen, hilft dieses Persönlichkeitsprofil, dessen Fundament das
          sogenannte Riemann-Thomann-Kreuz darstellt.
        </p>
      </div>
      <div className="header-right">
        <Link to={ROUTES.MEINE_PROFILE}>
          <img className="logo-right" src={teamGoldLogo} alt="Logo" />
        </Link>
      </div>
    </div>
    <div className="row">
      <div id="body">
        <AuthUserContext.Consumer>
          {(authUser) => <TestResultLinks authUser={authUser} />}
        </AuthUserContext.Consumer>
      </div>
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(
  TestPersoenlichkeitIntroPage
);
