import React, { Component } from "react";

import { withFirebase } from "../Firebase";

import Chart from "react-apexcharts";

class StilWirksamkeit extends Component {
  evalTable = {
    situation1: {
      A: 3,
      B: 1,
      C: 4,
      D: 1,
    },
    situation2: {
      A: 4,
      B: 1,
      C: 3,
      D: 1,
    },
    situation3: {
      A: 3,
      B: 4,
      C: 1,
      D: 1,
    },
    situation4: {
      A: 1,
      B: 3,
      C: 4,
      D: 1,
    },
    situation5: {
      A: 3,
      B: 1,
      C: 4,
      D: 1,
    },
    situation6: {
      A: 1,
      B: 3,
      C: 1,
      D: 4,
    },
    situation7: {
      A: 1,
      B: 4,
      C: 1,
      D: 3,
    },
    situation8: {
      A: 4,
      B: 1,
      C: 1,
      D: 3,
    },
    situation9: {
      A: 3,
      B: 1,
      C: 4,
      D: 1,
    },
    situation10: {
      A: 1,
      B: 1,
      C: 4,
      D: 3,
    },
    situation11: {
      A: 4,
      B: 1,
      C: 1,
      D: 3,
    },
    situation12: {
      A: 1,
      B: 4,
      C: 1,
      D: 3,
    },
    situation13: {
      A: 1,
      B: 4,
      C: 3,
      D: 1,
    },
    situation14: {
      A: 4,
      B: 1,
      C: 3,
      D: 1,
    },
    situation15: {
      A: 1,
      B: 3,
      C: 1,
      D: 4,
    },
    situation16: {
      A: 4,
      B: 1,
      C: 3,
      D: 1,
    },
    situation17: {
      A: 3,
      B: 1,
      C: 4,
      D: 1,
    },
    situation18: {
      A: 3,
      B: 4,
      C: 1,
      D: 1,
    },
    situation19: {
      A: 1,
      B: 4,
      C: 1,
      D: 3,
    },
    situation20: {
      A: 4,
      B: 1,
      C: 1,
      D: 3,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      result: null,
      stilWirk: 0,
      options: {
        chart: {
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#5D5C5C",
              strokeWidth: "100%",
              margin: 5, // margin is in pixels
              opacity: 0.2,
              dropShadow: {
                enabled: false,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: true,
                color: "var(--font-color)",
                fontSize: "1rem",
                fontFamily: "Red Hat Text, sans-serif",
                fontWeight: 300,
                offsetY: -30,
              },
              value: {
                offsetY: -70,
                color: "white",
                fontSize: "1rem",
                fontFamily: "Red Hat Text, sans-serif",
                formatter: function (val) {
                  return val.toFixed(0) + " %";
                },
              },
            },
          },
        },
        grid: {
          padding: {
            top: -20,
          },
        },
        fill: {
          type: "solid",
        },
        labels: ["STILWIRKSAMKEIT"],
        colors: ["var(--border-color)"],
      },
      series: [0, 0],
    };
  }

  calculateResult() {
    var sum = 0;
    for (var situation in this.state.result.result) {
      sum += this.evalTable[situation][this.state.result.result[situation]];
    }

    this.setState({
      stilWirk: sum,
      series: [(sum * 100) / 80],
    });
  }

  componentDidMount() {
    if (this.state.result) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .result("fstil", this.props.authUser.uid)
      .on("value", (snapshot) => {
        this.setState({
          result: snapshot.val(),
          loading: false,
        });
        this.state.result && this.calculateResult();
      });
  }

  componentWillUnmount() {
    this.props.firebase.result("fstil", this.props.authUser.uid).off();
  }

  render() {
    const { result, loading } = this.state;

    return (
      <div>
        {loading && <div>...</div>}

        {result && (
          <div className="text-col">
            <h3 className="font-colored">Auswertung der Stilwirksamkeit</h3>
            <div>
              Um eine hohe Bewertung bei der Stilwirksamkeit zu erreichen,
              müssen Sie nicht nur viel Flexibilität bei der Auswahl Ihrer
              Führungsstile zeigen, sondern auch den angemessenen Führungsstil
              für jede Situation aussuchen. Eine niedrige Bewertung weist auf
              schwache Stilwirksamkeit hin. Sie ist entstanden, weil Sie einige
              mäßig oder wenig passende Führungsstile für die 20 Situationen
              ausgesucht haben. Eine hohe Bewertung weist auf gute
              Stilwirksamkeit hin. Sie ist entstanden, weil Sie meistens gut
              oder ausgezeichnet passende Führungsstile aus gesucht haben.
            </div>
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="radialBar"
              height="500"
            />
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(StilWirksamkeit);
