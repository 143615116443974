import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navigation from "../NavigationFooter";
import SignInPage from "../SignIn";
import MeineTestsPage from "../Home";
import AdminPage from "../Admin";
import TestFuehrungsstilIntroPage from "../TestFuehrungsstilIntro";
import TestFuehrungsstilPage from "../TestFuehrungsstil";
import TestFuehrungsstilResultPage from "../TestFuehrungsstilResult";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import TestSelbstmanagementIntroPage from "../TestSelbstmanagementIntro";
import TestSelbstmanagementPage from "../TestSelbstmanagement";
import TestSelbstmanagementResultPage from "../TestSelbstmanagementResult";
import TestPersoenlichkeitIntroPage from "../TestPersoenlichkeitIntro";
import TestPersoenlichkeitPage from "../TestPersoenlichkeit";
import TestPersoenlichkeitResultPage from "../TestPersoenlichkeitResult";
import ImpressumPage from "../Impressum";
import DatenschutzPage from "../Datenschutz";

const App = () => (
  <Router>
    <div id="main-content">
      <Route exact path={ROUTES.LANDING} component={MeineTestsPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      {/* <Route
        path={ROUTES.PASSWORD_FORGET}
        component={PasswordForgetPage}
      /> */}
      <Route path={ROUTES.MEINE_PROFILE} component={MeineTestsPage} />
      {/* <Route path={ROUTES.ACCOUNT} component={AccountPage} /> */}
      <Route path={ROUTES.ADMIN} component={AdminPage} />
      <Route
        path={ROUTES.TEST_FUEHRUNGSSTIL_INTRO}
        component={TestFuehrungsstilIntroPage}
      />
      <Route
        path={ROUTES.TEST_FUEHRUNGSSTIL}
        component={TestFuehrungsstilPage}
      />
      <Route
        path={ROUTES.TEST_FUEHRUNGSSTIL_RESULT}
        component={TestFuehrungsstilResultPage}
      />
      <Route
        path={ROUTES.TEST_SELBSTMANAGEMENT_INTRO}
        component={TestSelbstmanagementIntroPage}
      />
      <Route
        path={ROUTES.TEST_SELBSTMANAGEMENT}
        component={TestSelbstmanagementPage}
      />
      <Route
        path={ROUTES.TEST_SELBSTMANAGEMENT_RESULT}
        component={TestSelbstmanagementResultPage}
      />
      <Route
        path={ROUTES.TEST_PERSOENLICHKEIT_INTRO}
        component={TestPersoenlichkeitIntroPage}
      />
      <Route
        path={ROUTES.TEST_PERSOENLICHKEIT}
        component={TestPersoenlichkeitPage}
      />
      <Route
        path={ROUTES.TEST_PERSOENLICHKEIT_RESULT}
        component={TestPersoenlichkeitResultPage}
      />
      <Route path={ROUTES.IMPRESSUM} component={ImpressumPage} />
      <Route path={ROUTES.DATENSCHUTZ} component={DatenschutzPage} />
    </div>
    <Navigation />
  </Router>
);

export default withAuthentication(App);
