import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import teamGoldLogo from "../../images/team_gold_logo.png";

const ImpressumPage = () => (
  <div className="main-col">
    <div className="header-top row">
      <Link to={ROUTES.MEINE_PROFILE}>
        <img className="logo-right" src={teamGoldLogo} alt="Logo" />
      </Link>
    </div>
    <div className="row">
      <div className="header-main">
        <h1>Impressum</h1>
        <h2>Angaben gemäß §5 TMG</h2>
      </div>
      <div className="header-right">
        <Link to={ROUTES.MEINE_PROFILE}>
          <img className="logo-right" src={teamGoldLogo} alt="Logo" />
        </Link>
      </div>
    </div>
    <div className="row">
      <div id="body">
        <p>
          GOLD Unternehmensentwicklung GmbH
          <br />
          Nürnberger Straße 16b
          <br />
          95448 Bayreuth
        </p>
        <p>
          Handelsregister: B 5086
          <br />
          Registergericht: Bayreuth
        </p>
        <p>
          Vertreten durch:
          <br />
          Florian Gold
        </p>
        <h2>Kontakt</h2>
        <p>
          Telefon: +49 921 162701-0
          <br />
          Telefax: +49 921 162701-11
          <br />
          E-Mail: hello@team-gold.de
        </p>
        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
          <br />
          DE 815 143 800
        </p>
        <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
        <p>Florian Gold</p>
        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr
          </a>
          .
          <br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
        <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h2>Haftung für Inhalte</h2>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>
        <h2>Urheberrecht</h2>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
      </div>
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

export default ImpressumPage;
