import React, {Component, forwardRef} from 'react';
import {Link} from 'react-router-dom';

import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as TESTS from '../../constants/tests';
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  onClickSendMailButton = event => {
    var uid = event.target.name

    this.props.firebase.sendWelcomeEmail({"uid": uid})
  }

  onChangeCheckbox = event => {
    if (event.target.name.startsWith("test")) {
      event.persist();

      var user = null
      var test = event.target.name.split("_")[0]
      var uid = event.target.name.split("_")[1]
      for (var i=0; i<this.state.users.length; i++) {
        if (this.state.users[i].uid === uid) {
          user = this.state.users[i]
          break;
        }
      }

      if (user) {
        if (event.target.checked) {
          const newTests = user.tests ? user.tests.concat(test) : [test];
          this.props.firebase.changeTests({
            "uid": uid,
            "tests" : newTests
          })
        }
        else {
          const newTests = user.tests.filter(currenttest => currenttest !== test);
          this.props.firebase.changeTests({
            "uid": uid,
            "tests" : newTests
          })
        }
      }
    }
  };

  getUserData(user) {
    return {
      name: user.username,
      email: user.email,
      admin: user.roles && user.roles["ADMIN"] === "ADMIN" ? "✔️" : "",
      uid: user.uid,
      initialPassword: user.initialPassword,
      tests: user.tests,
      userObject: user,
      checkboxHandler: this.onChangeCheckbox
    }
  }

  getTestLabels(test) {
    return (
      <div key={test+"_"+this.uid} >
        <label>
          <input name={test+"_"+this.uid} type="checkbox" checked={this.tests && this.tests.includes(test)} onChange={this.checkboxHandler} />
            <span style={{paddingLeft: "0.5rem"}}>{TESTS.tests[test].fullName}</span>
        </label>
      </div>
    )
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div style={{ maxWidth: '100%' }}>
        <h2>Benutzerliste</h2>
         {loading && <div>...</div>}
        <MaterialTable
            style={{backgroundColor: "var(--bg-color)", color:"var(--font-color)", boxShadow:"none", border: "1px solid white", borderRadius:"0px"}}
          icons={tableIcons}
          title="Benutzerliste"
          options={{
            pageSize: 5,
            pageSizeOptions: [5,10,25,50],
            draggable: false,
            showTitle: false,
          headerStyle:{backgroundColor: "var(--bg-color)", color:"var(--font-color)"}, searchFieldVariant:"standard",
          }}
          localization={{
            toolbar: {
              searchTooltip: 'Suchen',
              searchPlaceholder: 'Suchen'
            },
            pagination: {
              labelRowsSelect: 'Zeilen',
              labelDisplayedRows: '{from}-{to} von {count}',
              firstAriaLabel: 'Erste Seite',
              firstTooltip: 'Erste Seite',
              previousAriaLabel: 'Vorherige Seite',
              previousTooltip: 'Vorherige Seite',
              nextAriaLabel: 'Nächste Seite',
              nextTooltip: 'Nächste Seite',
              lastAriaLabel: 'Letzte Seite',
              lastTooltip: 'Letzte Seite'

            }
          }}
          columns={[
            { title: 'Name', field: 'name' ,
                render: (rowData) => (<div>
                                          <p>{rowData.name}</p>
                                          <Link to={{pathname: `${ROUTES.ADMIN}/${rowData.uid}`, state: { user: rowData.user } }} style={{textTransform: "none", fontWeight: "normal"}}>Details ↗</Link>
                                      </div>)
            },
            { title: 'E-Mail', field: 'email' },
            { title: 'Admin', field: 'admin', sorting: false},
            {
              title: 'Tests',
              field: 'tests', sorting: false,
              render: rowData => Object.keys(TESTS.tests).map(this.getTestLabels, rowData)
            },
            {
              title: 'Willkommens-Email',
              field: 'welcome', sorting: false,
              render: rowData => (rowData.initialPassword && <button name={rowData.uid} onClick={this.onClickSendMailButton} style={{fontWeight: "normal", whiteSpace: "nowrap"}}>Jetzt verschicken ↗</button>)
            }
          ]}

          data = {users.map(this.getUserData, this)}

        />
      </div>
    )
  }
}

export default withFirebase(UserList);
