import React from "react";
import { compose } from "recompose";
import { Link } from "react-router-dom";

import { withAuthorization } from "../Session";
import TestList from "./TestList";
import * as ROUTES from "../../constants/routes";

import teamGoldLogo from "../../images/team_gold_logo.png";

const MeineTestsPage = () => (
  <div className="main-col">
    <div className="header-top row">
      <Link to={ROUTES.MEINE_PROFILE}>
        <img className="logo-right" src={teamGoldLogo} alt="Logo" />
      </Link>
    </div>
    <div className="row">
      <div className="header-main">
        <h1>Meine Profile</h1>
        <h2>Im Folgenden finden Sie die Profile, die Ihnen zugeordnet sind.</h2>
      </div>
      <div className="header-right">
        <Link to={ROUTES.MEINE_PROFILE}>
          <img className="logo-right" src={teamGoldLogo} alt="Logo" />
        </Link>
      </div>
    </div>
    <div className="row">
      <div id="body">
        <TestList />
      </div>
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(MeineTestsPage);
