import React, { Component } from "react";
import { compose } from "recompose";

import { Link } from "react-router-dom";

import { AuthUserContext, withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import teamGoldLogo from "../../images/team_gold_logo.png";
import SelbstmanagementSurvey from "./SelbstmanagementSurvey";
import * as ROUTES from "../../constants/routes";

class TestSelbstmanagementPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="main-col">
        <div className="header-top row">
          <Link to={ROUTES.MEINE_PROFILE}>
            <img className="logo-right" src={teamGoldLogo} alt="Logo" />
          </Link>
        </div>
        <div className="row">
          <div className="header-main">
            <h1>Selbst- und Zeitmanagement</h1>
          </div>
          <div className="header-right">
            <Link to={ROUTES.MEINE_PROFILE}>
              <img className="logo-right" src={teamGoldLogo} alt="Logo" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div id="body">
            <AuthUserContext.Consumer>
              {(authUser) => (
                <SelbstmanagementSurvey
                  history={this.props.history}
                  authUser={authUser}
                />
              )}
            </AuthUserContext.Consumer>
          </div>
        </div>
      </div>
    );
  }
}

const condition = (authUser) => authUser && !!authUser.tests.includes("test1");

export default compose(
  withAuthorization(condition),
  withFirebase
)(TestSelbstmanagementPage);
